import React from 'react';
import TaxCalculator from '../components/TaxCalculator';

const CalculatorPage = () => (
  <section className="section--calculator">
    <TaxCalculator />
    <div>
      <h4>Informații</h4>
      <dl>
        <dt>Destinatar</dt>
        <dd>Trezorerie</dd>
        <dt>C.I.F./C.U.I.</dt>
        <dd>R14756536</dd>
        <dt>Localitate</dt>
        <dd>Timişoara</dd>
        <dt>Cont nr.</dt>
        <dd>RO65TREZ62121170210xxxxxx</dd>
        <dt>Cod Cont nr.:</dt>
        <dd>542</dd>
        <dt>Sucursala:</dt>
        <dd>Timişoara</dd>
        <dt>Contul IBAN:</dt>
        <dd>RO65TREZ62121070203xxxxx</dd>
        <dt>Localitatea</dt>
        <dd>Municipiul Timişoara CF.14756536</dd>
      </dl>
    </div>
    <div>
      <h4>
        Calcul taxa judiciară de timbru pentru acţiunile şi cererile evaluabile
        în bani &#8211; OUG nr. 80/2013, art. 3, alin. 1
      </h4>
      <ol type="a">
        <li>
          pana la valoarea de 500 lei &#8211; 8%, dar nu mai putin de 20 lei;
        </li>
        <li>
          intre 501 lei si 5.000 lei &#8211; 40 lei + 7% pentru ce depaseste 500
          lei;
        </li>
        <li>
          intre 5.001 lei si 25.000 lei &#8211; 355 lei + 5% pentru ce depaseste
          5.000 lei;
        </li>
        <li>
          intre 25.001 lei si 50.000 lei &#8211; 1.355 lei + 3% pentru ce
          depaseste 25.000 lei;
        </li>
        <li>
          intre 50.001 lei si 250.000 lei &#8211; 2.105 lei +2% pentru ce
          depaseste 50.000 lei;
        </li>
        <li>
          peste 250.000 lei &#8211; 6.105 lei + 1% pentru ce depaseste 250.000
          lei.
        </li>
      </ol>
      <p>
        În cazul acţiunilor in apel sau recurs cuantumul taxei este de 50% din
        valoarea taxei pe fond.
      </p>
    </div>
    <div>
      <h4>
        Calcul taxa de timbru în cazul cererilor în materia contractelor de
        achiziţii publice &#8211; Legea nr. 101/2016, art. 56
      </h4>
      <ol type="a">
        <li>
          până la 450.000 lei inclusiv &#8211; 2% din valoarea estimată a
          contractului;
        </li>
        <li>
          între 450.001 lei şi 4.500.000 lei inclusiv &#8211; 9.000 lei + 0,2%
          din ceea ce depăşeşte 450.001 lei;
        </li>
        <li>
          între 4.500.001 lei şi 45.000.000 lei inclusiv &#8211; 18.000 lei +
          0,02% din ceea ce depăşeşte 4.500.001 lei;
        </li>
        <li>
          între 45.000.001 lei şi 450.000.000 lei inclusiv &#8211; 27.000 lei +
          0,002% din ceea ce depăşeşte 45.000.001 lei;
        </li>
        <li>
          între 450.000.001 lei şi 4.500.000.000 lei inclusiv &#8211; 36.000 lei
          + 0,0002% din ceea ce depăşeşte 450.000.001 lei;
        </li>
        <li>
          peste 4.500.000.001 lei &#8211; 45.000 lei + 0,00002% din ceea ce
          depăşeşte 4.500.000.001 lei.
        </li>
      </ol>
      <p>
        În cazul acţiunilor in apel sau recurs cuantumul taxei este de 50% din
        valoarea taxei pe fond.
      </p>
    </div>
    <div>
      <h4>
        Calcul cauţiune &#8211; Codul de procedură civilă, art. 719, alin. 2
      </h4>
      <ol type="a">
        <li> de 10%, dacă această valoare este până la 10.000 lei;</li>
        <li> de 1.000 lei plus 5% pentru ceea ce depăşeşte 10.000 lei;</li>
        <li> de 5.500 lei plus 1% pentru ceea ce depăşeşte 100.000 lei;</li>
        <li>de 14.500 lei plus 0,1% pentru ceea ce depăşeşte 1.000.000 lei.</li>
      </ol>
    </div>
  </section>
);

export default CalculatorPage;
