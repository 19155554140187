import React from 'react';

class TaxCalculator extends React.Component {
  state = {
    input: 0,
  };

  handleInput = e => {
    const { value } = e.target;
    this.setState({ input: parseFloat(value) });
  };

  judiciaryTax = input => {
    let percent = 0;
    let tax = 0;
    if (isNaN(input) || input <= 0) return 0;
    if (input <= 500) {
      percent = input * 0.08;
      return percent > 20 ? percent : 20;
    }
    if (input <= 5000) {
      return 40 + (input - 500) * 0.07;
    }
    if (input <= 25000) {
      return 355 + (input - 5000) * 0.05;
    }
    if (input <= 50000) {
      return 1355 + (input - 25000) * 0.03;
    }
    if (input <= 250000) {
      return 2105 + (input - 50000) * 0.02;
    }
    return 6105 + (input - 250000) * 0.01;
  };

  stampTax = input => {
    if (isNaN(input) || input <= 0) return 0;
    if (input <= 450000) return input * 0.02;
    if (input <= 4500000) return 9000 + (input - 450001) * 0.002;
    if (input <= 45000000) return 18000 + (input - 4500001) * 0.0002;
    if (input <= 450000000) return 27000 + (input - 45000001) * 0.00002;
    if (input <= 4500000000) return 36000 + (input - 450000001) * 0.000002;
    return 45000 + (input - 450000001) * 0.0000002;
  };

  bail = input => {
    if (isNaN(input) || input <= 0) return 0;
    if (input <= 10000) return input * 0.1;
    if (input <= 100000) return 1000 + (input - 10000) * 0.05;
    if (input <= 10000000) return 5500 + (input - 100000) * 0.01;
    return 14500 + (input - 1000000) * 0.001;
  };

  render() {
    const { input } = this.state;
    const jt = this.judiciaryTax(input);
    const st = this.stampTax(input);
    const bail = this.bail(input);
    return (
      <form className="calculator">
        <label htmlFor="input">
          Suma
          <input
            type="number"
            className="input"
            name="input"
            id="input"
            onChange={this.handleInput}
            value={input}
          />
        </label>
        <label htmlFor="tjf">
          Taxa judiciară de timbru(Fond)
          <input
            type="number"
            className="input"
            name="input"
            id="tjf"
            value={jt.toFixed(2)}
            readOnly
          />
        </label>
        <label htmlFor="tjar">
          Taxa judiciară de timbru(Apel/Recurs)
          <input
            type="number"
            className="input"
            name="input"
            id="tjar"
            value={(jt / 2).toFixed(2)}
            readOnly
          />
        </label>
        <label htmlFor="ttaf">
          Taxa de timbru achiziții(Fond)
          <input
            type="number"
            className="input"
            name="input"
            id="ttaf"
            value={st.toFixed(2)}
            readOnly
          />
        </label>
        <label htmlFor="ttaar">
          Taxa de timbru achiziții(Apel/Recurs)
          <input
            type="number"
            className="input"
            name="input"
            id="ttaar"
            value={(st / 2).toFixed(2)}
            readOnly
          />
        </label>
        <label htmlFor="cautiune">
          Cauțiune
          <input
            type="number"
            className="input"
            name="input"
            id="cautiune"
            value={bail.toFixed(2)}
            readOnly
          />
        </label>
      </form>
    );
  }
}

export default TaxCalculator;
